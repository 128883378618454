<template>
  <el-dialog
    v-model="dialogFormVisible"
    :title="title"
    width="520px"
    @close="handleClose"
  >
    <el-form
      label-width="80px"
      :model="state.form"
      ref="formRef"
      :rules="state.rules"
    >
      <el-form-item label="手机号" prop="mobiles">
        <el-input
          :rows="5"
          type="textarea"
          v-model="state.form.mobiles"
          placeholder="请输入一个或多个手机号,多个手机号以逗号分隔"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click.stop="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSave">确认</el-button>
    </template>
  </el-dialog>
</template>

<script setup>
  import { reactive, ref, getCurrentInstance } from 'vue'
  import { reqAddDiscountCode, signUpByInside } from '@/api/exhibitionManage'
  import { debounce } from '@/utils/debounce'
  const props = defineProps({
    title: {
      type: String,
      default: '人工报名',
    },
  })
  const { proxy } = getCurrentInstance()
  const dialogFormVisible = ref(false)
  const state = reactive({
    form: {
      mobiles: '',
      ticketCode: '',
    },
    rules: {
      mobiles: [
        {
          required: true,
          trigger: 'blur',
          message: '请输入手机号',
        },
      ],
    },
  })
  const formRef = ref(null)
  const emit = defineEmits(['noticeRefresh'])
  const handleResetForm = () => {
    state.form = {
      mobiles: '',
      ticketCode: '',
    }
  }
  // 关闭
  const handleClose = () => {
    handleResetForm()
    dialogFormVisible.value = false
  }
  // 打开
  const handleOpen = (row = {}) => {
    // 重置表单
    state.form.ticketCode = row.ticketCode || ''
    dialogFormVisible.value = true
  }
  // 保存
  const handleSave = debounce(() => {
    formRef.value.validate(async (valid) => {
      if (valid) {
        try {
          let resp = await signUpByInside(state.form)
          proxy.$baseMessage(`报名成功`, 'success', 'vab-hey-message-success')
          emit('noticeRefresh')
        } catch (error) {
          proxy.$baseMessage(`报名失败`, 'error', 'vab-hey-message-error')
        } finally {
          handleClose()
        }
      }
    })
  })
  defineExpose({
    handleClose,
    handleOpen,
  })
</script>
